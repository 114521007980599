<template>
  <div>
    <div>
      <h3>App Info & Tips</h3>
      <ul>
        <li>
          The app is here to make participating in Yuletide easier by providing
          a better-looking interface than AO3. It uses the same data. During
          signups, it can be used to view the AO3 tagset and letters that
          participants have submitted while requests are hidden. When requests
          are revealed, it is a way of browsing all requests.
        </li>
        <li>
          If you
          <strong>can't find a fandom</strong>, remember the app shows 250 at a
          time unless you select Load Everything! If you still don't find it
          after that, please report a bug.
        </li>
        <li>
          During tagset corrections, the app is read-only: since fandoms and
          characters might be edited/removed, bookmarking and other features are
          disabled during this time.
        </li>
        <li>
          The
          <strong>Letters Column</strong> lists letters that were posted
          directly to this app or to the DW/LJ Letters Posts. It is
          <em>not</em> a comprehensive list of all letters or requests – those
          will be in the Requests column once requests are revealed.
        </li>
        <ul>
          <li>
            Adding a letter does
            <strong>not</strong> equal signing up. Sign up on AO3 (links pinned
            above).
          </li>
          <li>
            Mods sweep the letters post to add those that have not been added to
            the app to the app periodically.
          </li>
        </ul>
        <li>
          You can click the heart icon
          <span class="fas fa-heart"></span> to
          <strong>bookmark</strong> fandoms, letters, or prompts. These are
          saved in your browser.
        </li>
        <li>
          You can use your browser's
          <strong>back/forward</strong> buttons to preserve your scroll position
          if navigating back/forth from the main table to other tabs.
        </li>
        <li>
          Please report inaccuracies or bugs to the
          <a href="https://yuletide-admin.dreamwidth.org/105829.html" target="blank"
            >letters post</a
          >
          on DW or reach out on
          <a href="https://discord.gg/amR43JuT" target="blank">Discord</a>.
        </li>
      </ul>
    </div>
    <div>
      <h3>Basic Yuletide Info</h3>

      <ul>
        <li>
          Assignments must fulfil one of any of the recipient's requests with a
          completed fic of at least 1,000 words, include all requested
          characters, and avoid all Do Not Wants listed in the optional details
          section of your recipient's AO3 signup. Your recipient may give you
          permission to modify these requirements only in their optional
          details.
        </li>
        <!-- <li>
          Default deadline:
          {{ new Date("2023-12-11T09:00:00+00:00").toDateString() }}
          {{ new Date("2023-12-11T09:00:00+00:00").toLocaleTimeString() }}
          (local time)
        </li>
        <li>
          Assignments due:
          {{ new Date("2023-12-18T09:00:00+00:00").toDateString() }}
          {{ new Date("2023-12-18T09:00:00+00:00").toLocaleTimeString() }}
          (local time)
        </li> -->
        <li>
          <strong>
            Read the full ruleset
            <a
              href="https://archiveofourown.org/collections/yuletide2023/profile#faq"
              >here</a
            >
          </strong>
        </li>
        <li>
          <strong>Nominated Characters</strong> are all the characters in the
          tagset for that fandom. Yuletide only allows requests to uses
          characters from this list. If no characters are nominated, your author
          may write about any characters or about the fandom universe in
          general.
        </li>
        <li>
          <strong>Requested characters (0 - 4)</strong> in Yuletide use AND
          matching, meaning writers have to include all characters for that
          request. A request for "Any" means the writer can write any of the
          nominated characters they want, or about the fandom universe in
          general. You may not request Any and then insist on specific
          characters in your optional details.
        </li>
        <li>
          <strong>Optional details</strong> are what the requester put down in
          the AO3 signup. You <em>must</em> respect "Do Not Wants" (DNWs) if
          they are listed here. Requesters may also use this to give writers
          special permissions – for example, to use OR matching for characters.
        </li>
        <li>
          <strong>Letters</strong> are optional links to additional information
          provided by the requester. Details - including DNWs - in letters are
          <em>not</em> enforced by mods, since letters can be edited at any time
          by participants.
        </li>
        <li>
          <strong>Treats</strong> are any gifts written for a request but not
          written for an assignment. You may write treats for whomever you
          please, not just your assigned recipient. Treats that meet all
          requirements for Yuletide may go into the main collection and
          <em>may</em> turn into the recipient's main gift if their writer
          defaults. Treats that do not meet requirements must go into the
          Madness collection - for example, non-fic treats, treats under the
          wordcount. You may opt to put a treat that qualifies for the main
          collection into the Madness collection for whatever reason.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>
